/* eslint-disable @next/next/no-img-element */
import React from 'react';

import NextHead from 'next/head';

import { getCdnRawImageUrl } from '@/core/features/image-v2/image-utils';

type CdnRawImageProps = {
    alt: string;
    fetchPriority: 'high' | 'low';
    height?: number | string;
    preload: boolean;
    style?: React.CSSProperties;
    url: string;
    width?: number | string;
};

const _RawImage = (props: CdnRawImageProps) => {
    const { height = '100%', width = 'auto' } = props;

    const src = getCdnRawImageUrl(props.url);

    return (
        <>
            {props.preload && (
                <NextHead>
                    <link
                        as={'image'}
                        href={src}
                        rel={'preload'}
                    />
                </NextHead>
            )}
            <img
                alt={props.alt}
                fetchPriority={props.fetchPriority}
                height={height}
                src={src}
                style={props.style}
                width={width}
            />
        </>
    );
};

export default React.memo(_RawImage);
