import type { ResponsiveImageProps } from '@/core/features/image-v2/responsive-image';

import React from 'react';

import { ResponsiveImage } from '@/core/features';

const _FullscreenImage = (props: Pick<ResponsiveImageProps, 'alt' | 'className' | 'maxHeight' | 'url' | 'zoom'>) => {
    return (
        <ResponsiveImage
            {...props}
            className={'height-100'}
            fetchPriority={'high'}
            height={'full'}
            layoutWidth={100}
            objectFit={'contain'}
            preload={false}
        />
    );
};

export default React.memo(_FullscreenImage);
