/* eslint-disable @next/next/no-img-element */
import React from 'react';

import NextHead from 'next/head';

import { getCdnImageUrl } from '@/core/features/image-v2/image-utils';
import { useCookieConsentBannerVisibleState } from '@/core/features/wireframe/wireframe-manipulation/consent-banner/use-wireframe-consent-banner-visible';
import { bemModule } from '@/core/utils/bem-classname';

import styles from './image.module.scss';

export type ImageProps = {
    alt: string;
    container?: 'both' | 'horizontal' | 'vertical';
    fetchPriority?: 'high' | 'low';
    forceHydrate?: boolean;
    height: number; // px value
    maxHeight?: number;
    objectFit?: 'contain' | 'cover';
    preload: boolean;
    url: string;
    width: number; // px value
};

const bem = bemModule(styles);

const Image = ({
    alt,
    container,
    fetchPriority,
    forceHydrate = false,
    height,
    maxHeight,
    objectFit = 'cover',
    preload,
    url,
    width,
}: ImageProps) => {
    const { placeholderSrc, src } = React.useMemo(() => {
        const src = getCdnImageUrl(url, width * 2, height * 2);
        const placeholderSrc = getCdnImageUrl(url, 16, 16);

        return { placeholderSrc, src };
    }, [url, width, height]);

    const imageRef = React.useRef<HTMLImageElement>(null);
    const wasLoadedRef = React.useRef(false);

    const isCookieConsentBannerVisible = useCookieConsentBannerVisibleState();

    const [isLoading, setLoading] = React.useState(!forceHydrate);
    const isHydrated = forceHydrate || !isCookieConsentBannerVisible;
    const showPlaceholder = !isHydrated || isLoading;
    const preloadSrc = showPlaceholder ? placeholderSrc : src;

    const finishLoading = React.useCallback(() => {
        wasLoadedRef.current = true;
        if (!isLoading) {
            return;
        }
        setLoading(false);
    }, [isLoading]);

    React.useEffect(() => {
        if (!imageRef.current) {
            return;
        }
        if (imageRef.current.complete) {
            finishLoading();
        } else {
            const loadingTimeout = setTimeout(() => {
                if (!wasLoadedRef.current) {
                    setLoading(true);
                }
            }, 400);

            imageRef.current.onload = () => {
                clearTimeout(loadingTimeout);
                finishLoading();
            };

            return () => {
                clearTimeout(loadingTimeout);
            };
        }
        return;
    }, [finishLoading, isCookieConsentBannerVisible]);

    return (
        <Container
            container={container}
            height={height}
            width={width}
        >
            <div
                className={`absolute-full ${bem(styles.imageWrapper, { [objectFit]: true, showPlaceholder })}`}
                style={{
                    backgroundImage: `url(${placeholderSrc})`,
                    height: maxHeight !== undefined ? `${maxHeight}px` : undefined,
                }}
            >
                {isHydrated && (
                    <img
                        alt={alt}
                        className={`${bem(styles.image, { [objectFit]: true })}`}
                        fetchPriority={fetchPriority}
                        height={height}
                        loading={preload ? 'eager' : 'lazy'}
                        ref={imageRef}
                        src={src}
                        width={width}
                    />
                )}
                {preload && (
                    <NextHead>
                        <link
                            as={'image'}
                            href={preloadSrc}
                            rel={'preload'}
                        />
                    </NextHead>
                )}
            </div>
        </Container>
    );
};

export default React.memo(Image);

const Container = ({
    children,
    container,
    height,
    width,
}: React.PropsWithChildren<Pick<ImageProps, 'container' | 'height' | 'width'>>) =>
    container ? (
        <div
            className={'relative'}
            style={{
                height: container === 'vertical' || container === 'both' ? height : undefined,
                width: container === 'horizontal' || container === 'both' ? width : undefined,
            }}
        >
            {children}
        </div>
    ) : (
        <>{children}</>
    );
